<template>
  <div>
      <h2>Market Insights</h2>
       <b-form ref="form" @submit.stop.prevent="updateMarketInsights">
           <b-container fluid>
               <b-row class="my-1">
                    <b-col sm="3" >
                          <label for="txtInflation">Inflation :</label>
                    </b-col>
                     <b-col sm="2">
                        <b-form-input 
                            id="txtInflation" 
                            name="txtInflation"
                            v-model="inflation"
                            step="0.01" min="0.00" max="100.00"
                            type="number">
                        </b-form-input>
                    </b-col>
                    <b-col cols="2" align-v="center">
                           %    
                    </b-col>
               </b-row >
                <b-row class="my-1">
                    <b-col sm="3" >
                          <label for="GDP">GDP and YoY Growth :</label>
                    </b-col>
                     <b-col sm="2">
                        <b-form-input 
                        id="txtGDP" 
                        name="txtGDP"
                        v-model="gdp"
                        type="number"></b-form-input>
                    </b-col>
                    <b-col cols="2" align-v="center">
                           %    
                    </b-col>
               </b-row>
                <b-row class="my-1">
                    <b-col sm="3" >
                          <label for="CostOfLiving">Cost of living and Y.O.Y Change :</label>
                    </b-col>
                     <b-col sm="2">
                        <b-form-input 
                        id="txtCostOfLiving" 
                        name="txtCostOfLiving"
                        v-model="costOfLiving"
                        type="number"></b-form-input>
                    </b-col>
                    <b-col cols="2" align-v="center">
                           %    
                    </b-col>
               </b-row>
               <b-row class="my-1">
                    <b-col sm="3" >
                          <label for="UnEmployment">UnEmployment :</label>
                    </b-col>
                     <b-col sm="2">
                        <b-form-input 
                        id="txtUnEmployment" 
                        name="txtUnEmployment"
                        v-model="unEmployment"
                        type="number"></b-form-input>
                    </b-col>
                    <b-col cols="2" align-v="center">
                           %    
                    </b-col>
               </b-row>
                <b-row class="my-1">
                    <b-col sm="3" >
                          <label for="InterestRates">Interest Rates :</label>
                    </b-col>
                     <b-col sm="2">
                        <b-form-input 
                        id="txtInterestRates" 
                        name="txtInterestRates"
                        v-model="interestRates"
                        type="number"></b-form-input>
                    </b-col>
                    <b-col cols="2" align-v="center">
                           %    
                    </b-col>
               </b-row>
                <b-row class="my-1">
                    <b-col sm="3" >
                          <label for="FX">F.X USD over EGP :</label>
                    </b-col>
                     <b-col sm="2">
                        <b-form-input 
                        id="txtFX"
                        name="txtFX"
                        v-model="fx"
                        type="number"></b-form-input>
                    </b-col>
                    <b-col cols="2" align-v="center">
                           %    
                    </b-col>
               </b-row>
                 <b-row class="my-1">
                    <b-col sm="3" >
                          <label for="Population">Population Min and Y.O.Y Growth :</label>
                    </b-col>
                     <b-col sm="2">
                        <b-form-input 
                        id="txtPopulation"
                        name="txtPopulation"
                        v-model="population" 
                        type="number">
                        </b-form-input>
                    </b-col>
                    <b-col cols="1">
                           %    
                    </b-col>
               </b-row>
                <b-row class="my-1">
                     <b-col sm="2">
                         <b-button block variant="success" size="lg" type="submit">
                            Save
                        </b-button>
                    </b-col>
                </b-row>
           </b-container>
       </b-form>
  </div>
</template>

<script>
export default {
    data(){
        return{
            id:null,
            inflation:null,
            gdp:null,
            costOfLiving:null,
            unEmployment:null,
            interestRates:null,
            fx:null,
            population:null
        }
    },
    methods:{
        loadMarketInsights(){
             this.$axios({
                method: 'get',
                url: `${this.$baseURL}/MarketInsights?surveyYear=${this.$store.getters.surveyYear}`,
            })
            .then((response)=>{
                this.id = response.data.id;
                this.inflation = response.data.inflation != -1 ? response.data.inflation:null ;
                this.gdp = response.data.gdp !=-1 ? response.data.gdp : null;
                this.costOfLiving = response.data.costOfLiving !=-1?response.data.costOfLiving:null;
                this.unEmployment = response.data.unEmployment !=-1?response.data.unEmployment:null;
                this.interestRates = response.data.interestRates!=-1?response.data.interestRates:null;
                this.fx = response.data.fx!=-1?response.data.fx:null;
                this.population = response.data.population!=-1?response.data.population:null;
            });
        },
        updateMarketInsights(){
            
             this.$axios({
                method: 'put',
                headers: {'Content-Type': 'application/json' },
                url: `${this.$baseURL}/MarketInsights`,
                data: {
                        Id:this.id!=null ?this.id:-1,
                        SurveyYear: this.$store.getters.surveyYear,
                        Inflation: Number(this.inflation!=null?this.inflation:-1),
                        GDP:Number(this.gdp!=null?this.gdp:-1),
                        CostOfLiving:Number(this.costOfLiving!=null?this.costOfLiving:-1),
                        UnEmployment:Number(this.unEmployment!=null?this.unEmployment:-1),
                        InterestRates:Number(this.interestRates!=null?this.interestRates:-1),
                        FX:Number(this.fx!=null?this.fx:-1),
                        Population:Number(this.population!=null?this.population:-1)
                    }
            }).then(()=>{
                this.$bvModal.msgBoxOk('Market Insights updated successfully', {
                                            title: 'Confirmation',
                                            size: 'sm',
                                            buttonSize: 'sm',
                                            okVariant: 'success',
                                            headerClass: 'p-2 border-bottom-0',
                                            footerClass: 'p-2 border-top-0',
                                            centered: true
                                            });
            });
        }
    },
    mounted(){
        this.loadMarketInsights();
    }

}
</script>

<style>

</style>